import React from "react";
import ErrorImg from "../images/error.png";

export default function ErrorOverlay(props) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#e0e0e0e6",
        zIndex: "999999",
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "#FF2A2A",
        justifyContent: "center",
        overflow: "hidden",
        top: 0,
        left: 0,
      }}
    >
      <img
        src={ErrorImg}
        alt="error"
        style={{ height: "100px", width: "100px" }}
      />
       {props.active ?
      <h3> Payment page ref is not correct</h3>
      :
      <h3> Payment page is not active for this merchant.</h3>
       }
    </div>
  );
}
