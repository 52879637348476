import React from "react";
import { Button, Typography, Box } from "@mui/material";

export default function CustomButton(props) {
  const { variant, onClick, disabled, text } = props;
  return (
    <Box display="flex" justifyContent="center">
    <Button
      style={
        variant === "contained"
          ? {
              backgroundColor: `${
                    props.contentState &&
                    props.contentState.merchant_details &&
                    props.contentState.merchant_details.brand_primary_color
                      ? props.contentState.merchant_details.brand_primary_color
                      : "#000"
                  }`,
                opacity: disabled
                ? "0.5"
                : "1",
              color: disabled ? "#ffffff" : "#ffffff",
              width: "302px",
              textAlign:"center",
              fontSize: "16px",
              textTransform: "none",
            }
          : {
              color: "#000",
              textTransform: "none",
            }
      }
      onClick={onClick}
      variant={variant ? variant : "contained"}
      disabled={disabled}
    >
      {text}
    </Button>
    </Box>
  );
}
