import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: localStorage.getItem("language") === "VND" ? "vn" : localStorage.getItem("language") === "THB" ? "th" : localStorage.getItem("language") === "SGP" ? "sg" : localStorage.getItem("language") === "IDR" ? "id" : localStorage.getItem("language") === "PHP" ? "ph"  : "en",
  lng: localStorage.getItem("language") === "VND" ? "vn" : localStorage.getItem("language") === "THB" ? "th" : localStorage.getItem("language") === "SGP" ? "sg" : localStorage.getItem("language") === "IDR" ? "id"  : localStorage.getItem("language") === "PHP" ? "ph" : "en",
  resources: {
    en: {
      translations: require("./locales/en/translations.json"),
    },
    vn: {
      translations: require("./locales/vn/translations.json"),
    },
    th: {
      translations: require("./locales/th/translations.json"),
    },
    sg: {
      translations: require("./locales/sg/translations.json"),
    },
    id: {
      translations: require("./locales/id/translations.json"),
    },
    ph: {
      translations: require("./locales/ph/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "vn", "th", "sg", "id", "ph"];

export default i18n;
