import React from "react";
import {
  InputBase,
  alpha,
  InputLabel,
  FormControl,
  FormHelperText,
  Tooltip,
  Box
} from "@mui/material";
import styled from "@emotion/styled";
import TooltipImg from "../../images/info.svg";
import TooltipImage from "../tooltip";
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ClassNames } from "@emotion/react";

const CustomDatePicker = styled(DatePicker)(({theme}) => ({
  "& .MuiFormControl-root .MuiOutlinedInput-root": {
    width:"100%",
  },

}))


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    //marginTop: theme.spacing(3),
  },
  ".css-yz9k0d-MuiInputBase-input.Mui-disabled": {
    textFillColor: "#000",
    opacity: "0.5",
    fontSize: "14px",
    fontWeight: "400",
    borderBlock:"none",
    borderInline:"none",
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    //backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #0024644D",
    fontSize: "14px",
    color: "black",
    //opacity: "1 !important",
    width: "100%",
    fontWeight: "400",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      //borderColor: theme.palette.primary.main,
    },
    "&::placeholder": {
      //color: "#000",
      opacity: "0.6",
      fontSize: "13px",
      fontWeight: "400",
    },
  },

}));

export default function CustomInput(props) {
  const {
    id,
    variant = "standard",
    type,
    name,
    value,
    onChange,
    onBlur,
    onFocus,
    disabled,
    readOnly,
    label,
    maxLength,
    max,
    min,
    isdark,
    tooltip,
    error,
    errorText,
    placeholder,
    required,
  } = props;
  const exceptThisSymbols = ["e", "E", "+", "-"];
  return (
    <>
      {type === "datepicker" ?
      <div style={{ margin: "12px 0 0px 0" }}>
          
          
          <InputLabel shrink htmlFor="bootstrap-input" style={ isdark ? {  color:"#707070", fontSize: "18px", fontWeight:"700", lineHeight:"30px", position:"relative", whiteSpace:"pre-line"} : { fontSize: "18px", fontWeight:"700", color:"rgba(0,0,0,0.75)", lineHeight:"30px", position:"relative", whiteSpace:"pre-line"}}>
          {label} <span style={{ color: "red" }}> {required ? "*" : ""} </span>
          {tooltip !== "" ?
          <span style={{ marginLeft:"2px", marginTop:"5px"}}> 
            <Tooltip title={tooltip}>
              <span>
              <TooltipImage color = { isdark ? "#707070" : "#fff"} />
              </span>
            </Tooltip> 
          </span>
          : ""
          }
          </InputLabel>
          
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormControl variant="standard" fullWidth>
          <CustomDatePicker
            //classes = {{ root: {width: "100%"}}}
            id={id}
            fullWidth
            inputFormat="yyyy-MM-dd"
            //variant={variant}
            variant="standard"
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            //onBlur={onBlur}
            //onFocus={onFocus}
            // readOnly={readOnly}
            disabled={disabled}
            error={error}
            required={required}
            renderInput={(params) => 
            
            <TextField {...params} 
            sx={ isdark ? {
              ".MuiInputBase-input ": {height:"14px",
              //border:"1px solid #FFFFFF1A !important",
              borderRight:"none !important",
              color:"#ffffff",
              "&::placeholder":{color:"#ffffff"},
              padding:"14px 14px"
              

              },
              '.MuiSvgIcon-root':{ width:"20px", height:"20px", color:"#FFFFFF1A"},
              ".MuiOutlinedInput-notchedOutline":{
                border:"1px solid #FFFFFF1A",
                color:"#ffffff",
                
              },
              "&:hover":{border:"1px solid #FFFFFF1A"}
               
           }:{
            ".MuiInputBase-input ": {height:"14px",padding:"14px 14px",
            "&::placeholder":{color: "#000"}
              },
              '.MuiSvgIcon-root':{ width:"20px", height:"20px"},
              ".MuiOutlinedInput-notchedOutline":{
                border:"1px solid #0024644D"
              },
              

           }}
            helperText={""} 
            inputProps={{
              ...params.inputProps,
              
              placeholder: "yyyy-mm-dd",
              border:"none"
            
            }}
     />}
          />
          </FormControl>
          
          </LocalizationProvider>
          <br/>
          <FormHelperText style={{ color: "red", fontSize: "12px", marginTop:"10px" }}>
            {error && errorText}
          </FormHelperText>
          
          
          </div>
          

      :
      <div style={{margin: "12px 0 0px 0"}}>
      <FormControl variant="standard" fullWidth sx={{ "& .Mui-error":{ border: "1px solid red", borderRadius:"4px" } , "& .MuiInputBase-input": { "&:focus": {
      boxShadow: error ? `#e57373 0 0 0 0.2rem !important`: `${alpha("#81c784", 0.25)} 0 0 0 0.2rem`
      
    }, "&:invalid":  {boxShadow: error ? `${alpha("#e57373", 0.25)} 0 0 0 0.2rem !important` : "initial" }}}}>
        <InputLabel shrink htmlFor="bootstrap-input" style={ isdark ? {  color:"#707070", fontSize:"18px", fontWeight:"700", position:"relative", whiteSpace:"pre-line"}: { fontSize:"18px", fontWeight:"700", color:"rgba(0,0,0,0.75)", position:"relative", whiteSpace:"pre-line"}}>
          {label} <span style={{ color: "red" }}> {required ? "*" : ""} </span>
          {tooltip !== "" ?
          <span style={{ marginTop: "5px", textAlign:"center", marginLeft:"2px"}}> 
            <Tooltip title={tooltip}>
              {/* <img src={TooltipImg} alt={"info icon"} width="15px" />  */}
              <span>
              <TooltipImage color = { isdark ? "#707070" : "#fff"} />
              </span>
            </Tooltip> 
          </span>
          : ""
          }
        </InputLabel>
        {
          type ==="textarea" ?
          <BootstrapInput
          id={id}
          fullWidth
          variant={variant}
          placeholder={placeholder}
          sx={ isdark ? { "& .MuiInputBase-input":{color:"#ffffff !important", border:"1px solid #FFFFFF1A !important"}, "&::placeholder":{color:"#ffffff !important"}, 
             } :{color: "#000"}}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          readOnly={readOnly}
          disabled={disabled}
          error={error}
          required={required}
          multiline
          rows={4.5}
          inputProps={{ maxLength: maxLength, max: max, min: min }}
          // helperText={(error && errorText)}
          style={{ outline:"none", height:"128px"}}
        />
          :
        <BootstrapInput
          id={id}
          fullWidth
          variant={variant}
          placeholder={placeholder}
          type={type}
          name={name}
          sx={ isdark ? { "& .MuiInputBase-input":{color:"#ffffff !important", border:"1px solid #FFFFFF1A !important", height:"19px"}, "&::placeholder":{color:"#ffffff !important"} } :{"& .MuiInputBase-input": {height:"19px"} ,color: "#000" }}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          readOnly={readOnly}
          disabled={disabled}
          error={error}
          required={required}
          onKeyDown={(e) => type == "number" ?  exceptThisSymbols.includes(e.key) && e.preventDefault() : {}}
          onWheel={(e) => type == "number" ?  e.target.blur() : {}} 
          inputProps={{ maxLength: maxLength, max: max, min: min }}
          helperText={(error && errorText)}
          //style={{ marginTop:"29px"}}
        />
        }
        <FormHelperText style={{ color: "red", fontSize: "12px" }}>
          {error && errorText}
        </FormHelperText>
      </FormControl>
      </div>
      }
    
    
    </>
  )
  
}
