const EmailRegex = /^([a-z+\d\._!#$%^&*=-]+)@([a-z\d-]+)\.([a-z]{2,30})(\.[a-z]{2,6})?$/i;
const nameRegex = /^[a-zA-Z ]*$/;
const IsRequiredRegex = /^\s*$/;
const UrlRagex = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/;
const DateRagex = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;

var datevalidation =  function(date){
    var date1 = new Date(date),
    mnth = ("0" + (date1.getMonth() + 1)).slice(-2),
    day = ("0" + date1.getDate()).slice(-2);
    var c = [date1.getFullYear(), mnth, day].join("-");
    if(date === ""){
        return false;
    }
    else{ 
        return !DateRagex.test(c);
    }
}

export const validateEmail = (email) => email !== "" ? !EmailRegex.test(email): false;
export const validateName = (value) => !nameRegex.test(value);
export const validateRequired = value => IsRequiredRegex.test(value);
export const validateUrl = url => url === "" ? false : !UrlRagex.test(url);
export const validateDate = (date, x) => x === "" ? false : datevalidation(date, x);



export const validator = (type, value, d) => {
    switch(type){
        case "email":
            return validateEmail(value);
        case "phone_number":
            console.log(value.length)
            if(value.length === 3 && d === "p")
                return false;
            else if(value.length < 10)
                return true;
            else
                return false;
        case "url":
            return validateUrl(value);

        case "datepicker":
           return validateDate(value, d);

        default:
            return false;
    }
}
