import React from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { Block } from "@mui/icons-material";

const useStyles = makeStyles((theme) =>
  createStyles({
    MainContainer: {
      // [theme.breakpoints.up('xs')]: {
      //     padding: '0 0'
      // },
      [theme.breakpoints.up("md")]: {
        padding: "39px 0rem 0rem 0rem",
        marginTop:"0px",
        //backgroundColor:"#F5F5F5"
      },
      [theme.breakpoints.down("md")]: {
        //padding: "0rem 0rem 0rem 1rem",
        margin :"0 1rem"
      },
    },
  })
);

export default function MainContainer(props) {
  const classes = useStyles();

  return (
    <div
      data-testid={`${props.testId}-main-container-div`}
      className={classes.MainContainer}
    >
      {props.children}
    </div>
  );
}
