import React from "react";

export default function MailImage(props) {
  return (
<span style={{ marginRight:"4px", marginTop: "4px"}}>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={!props.isdark ? "#fff" : "#1A1818"} viewBox="0 0 25 19"><defs>
     
      <clipPath id="b"><rect width="20" height="20"/></clipPath>
      </defs><g id="a" class="a"><rect class="c" width="20" height="20" /><g transform="translate(0.384 0.755)">
        <path class="b" d="M0,15.276V1.787A2.4,2.4,0,0,1,1.787,0H21.707a2.407,2.407,0,0,1,1.787,1.787V15.276a2.411,2.411,0,0,1-1.787,1.787H1.787A2.408,2.408,0,0,1,0,15.276M1.786,1.105c.12.14.187.232.266.311q4.441,4.428,8.886,8.852a1.022,1.022,0,0,0,1.63-.007q4.444-4.425,8.883-8.855c.077-.077.14-.169.247-.3ZM15.01,9.3c-.569.575-1.137,1.163-1.72,1.737a2.087,2.087,0,0,1-3.092-.006c-.581-.575-1.145-1.168-1.692-1.729l-6.587,6.65H21.591L15.01,9.3M1.078,15.063,7.495,8.594,1.078,2.106ZM16,8.5l6.4,6.467V2.042L16,8.5" transform="translate(0.369 0.214)" fill={props.color1} /></g>
        </g></svg>
  
</span>
    
  
      
    
  );
}
