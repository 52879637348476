import React from "react";

export default function PhoneImage(props) {
  return (
<div style={{ marginRight:"4px", marginTop: "6px"}}>
<svg xmlns="http://www.w3.org/2000/svg"  width="20" height="20" viewBox="0 0 20 20">
  <defs>
  <clipPath id="b"><rect width="20" height="20"/></clipPath>
  </defs>
  <g id="a" class="a"><rect class="c" width="20" height="20" fill={!props.isdark ? "#fff" : "#1A1818"} /><g transform="translate(0 1)">
    <path class="b" d="M9,0A9,9,0,1,1,0,9,9.033,9.033,0,0,1,9,0m0,17.105a8.107,8.107,0,1,0-8.114-8.1,8.137,8.137,0,0,0,8.114,8.1" transform="translate(1)" fill={props.color1}/>
    <path class="b" d="M53.713,92.3A7.344,7.344,0,0,1,49.38,90.81a9.9,9.9,0,0,1-4.039-5.393,7.25,7.25,0,0,1-.259-1.864,1.676,1.676,0,0,1,1.479-1.844,3.059,3.059,0,0,1,.587-.072.973.973,0,0,1,.945.553,5.056,5.056,0,0,1,.631,2.005,1.7,1.7,0,0,1-.532,1.488.543.543,0,0,0-.154.757,6.1,6.1,0,0,0,3.025,2.969.552.552,0,0,0,.682-.085,1.9,1.9,0,0,1,1.95-.515,8.4,8.4,0,0,1,1.382.474,1,1,0,0,1,.628.9,2.622,2.622,0,0,1-.291,1.449,1.2,1.2,0,0,1-1.05.658c-.216.015-.434,0-.651,0" transform="translate(-40.137 -77.969)" fill={props.color1} /></g><g transform="translate(0 0)">
    <path class="b" d="M9,0A9,9,0,1,1,0,9,9.033,9.033,0,0,1,9,0m0,17.105a8.107,8.107,0,1,0-8.114-8.1,8.137,8.137,0,0,0,8.114,8.1" transform="translate(1 1)" fill={props.color1}/>
    <path class="b" d="M53.713,92.3A7.344,7.344,0,0,1,49.38,90.81a9.9,9.9,0,0,1-4.039-5.393,7.25,7.25,0,0,1-.259-1.864,1.676,1.676,0,0,1,1.479-1.844,3.059,3.059,0,0,1,.587-.072.973.973,0,0,1,.945.553,5.056,5.056,0,0,1,.631,2.005,1.7,1.7,0,0,1-.532,1.488.543.543,0,0,0-.154.757,6.1,6.1,0,0,0,3.025,2.969.552.552,0,0,0,.682-.085,1.9,1.9,0,0,1,1.95-.515,8.4,8.4,0,0,1,1.382.474,1,1,0,0,1,.628.9,2.622,2.622,0,0,1-.291,1.449,1.2,1.2,0,0,1-1.05.658c-.216.015-.434,0-.651,0" transform="translate(-40.137 -76.969)" fill={props.color1}/></g>
    </g></svg>
</div>


    
  
      
    
  );
}
