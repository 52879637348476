import Axios from "axios";
Axios.defaults.timeout = 30000;

export default class request {
  static token = "";
  static async get(url, key) {
    try {
      let response = await Axios.get(
        `${process.env.REACT_APP_CHAIPORT_BACKEND_API_URL}/api/${url}/${key}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Chaipay-Client-Key": "SglffyyZgojEdXWL",
          },
        }
      );

      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        return {
          status: 400,
          data: {
            message: "Sorry! Server is not responding. Please try again later.",
          },
        };
      }
    }
  }

  static async post(url, data, jwtkey, chaipaykey) {
    try {
      let response = await Axios.post(
        `${process.env.REACT_APP_CHAIPORT_BACKEND_API_URL}/api/${url}`,
        data,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Chaipay-Client-Key": `${chaipaykey}`,
            "Authorization" : `Bearer ${jwtkey}`
          },
        }
      );

      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        return {
          status: 400,
          data: {
            message: "Sorry! Server is not responding. Please try again later.",
          },
        };
      }
    }
  }

  static async patch(url, data, key) {
    try {
      let response = await Axios.patch(
        `${process.env.REACT_APP_CHAIPORT_BACKEND_API_URL}/api/${url}`,
        data,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        }
      );

      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        return {
          status: 400,
          data: {
            message: "Sorry! Server is not responding. Please try again later.",
          },
        };
      }
    }
  }
}
