import React from "react";

export default function TooltipImage(props) {
  return (
<span style={{ marginRight:"4px", marginTop: "4px"}}>
<svg xmlns="http://www.w3.org/2000/svg" width={props.size ? props.size : "15"} height={props.size ? props.size : "15"} viewBox="0 0 12 12">
  <g id="Group_86235" data-name="Group 86235" transform="translate(-985.495 -189)">
    <g id="Ellipse_1247" data-name="Ellipse 1247" transform="translate(985.495 189)" fill="rgba(255,255,255,0)" stroke="#000" stroke-width="1">
      <circle cx="6" cy="6" r="6" stroke="none"/>
      <circle cx="6" cy="6" r="5.5" fill={props.color}/>
    </g>
    <g id="Group_86236" data-name="Group 86236">
      <g id="Rectangle_3774" data-name="Rectangle 3774" transform="translate(990.495 194.5)" fill={props.color} stroke="#000" stroke-width="1">
        <rect width="2" height="4" rx="1" stroke="none"/>
        <rect x="0.5" y="0.5" width="1" height="3" rx="0.5" fill={props.color}/>
      </g>
      <g id="Ellipse_1248" data-name="Ellipse 1248" transform="translate(990.495 191.5)" fill="#707070" stroke="#000" stroke-width="1">
        <circle cx="1" cy="1" r="1" stroke="none"/>
        <circle cx="1" cy="1" r="0.5" fill={props.color}/>
      </g>
    </g>
  </g>
</svg>

    
  
</span>
    
  
      
    
  );
}
